




import Vue from 'vue';
import { Route, NavigationGuardNext } from 'vue-router';
import Log from '@/resources/plugins/Logger/Log';
import ServiceFactory from '@/services/ui/ServiceFactory';
import store from '@/store';
import { DomainAuth } from '@/store/modules/domain/auth';
import { UICommon } from '@/store/modules/ui/common';
// import { UIGoogleCalendarMapper } from '@/store/modules/ui/googlecalendar';

const UserService = ServiceFactory.get('user');

export default Vue.extend({
  name: 'GoogleCalendarLinkResult',

  // computed: {
  //   ...UIGoogleCalendarMapper.mapState(['toastCheck']),
  // },

  async beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext) {
    // console.log('beforeRouterEnter to check', to);
    // console.log('beforeRouterEnter from check', from);
    const result = Vue._.get(to.params, 'result', 'failure');
    const domainAuthContext = DomainAuth.context(store);
    const { userAttributes } = domainAuthContext.state;
    const { workspaceId, userId } = userAttributes;
    const { setUserAttributes } = domainAuthContext.actions;
    // pathのクエリ取得
    const query = Vue._.get(to.query, 'status', 'none');

    if (!workspaceId || !userId) {
      next({ name: 'Login' });
      return;
    }

    const uiCommonContext = UICommon.context(store);
    const { setMessage } = uiCommonContext.actions;

    try {
      const responseUser = await UserService.getUser(workspaceId, userId);
      setUserAttributes(responseUser);

      next(() => {
        // vm.setToastCheck(true);
        // if (vm.toastCheck) {
        if (result === 'success') {
          setMessage({ color: 'success', text: 'Googleカレンダーとの連携に成功しました。' });
        } else if (result === 'failure' && query === 'already_connected') {
          setMessage({ color: 'error', text: 'Googleカレンダーとはすでに連携済みです。' });
        } else if (result === 'failure' && query === 'internal_server_error') {
          setMessage({
            color: 'error',
            text:
              'システム内部でエラーが発生しました。時間を空けてから再度お試しください。\nこのエラーが繰り返し表示される場合、COMSQUAREまでお問い合わせください。',
          });
        } else if (result === 'failure' && query === 'unauthorized') {
          setMessage({
            color: 'error',
            text:
              'Googleカレンダーに紐づくユーザー情報を特定できませんでした。再ログインして再度お試しください。\nこのエラーが繰り返し表示される場合、COMSQUAREまでお問い合わせください。',
          });
        } else if (result === 'failure' && query === 'workspace_id_not_found') {
          setMessage({
            color: 'error',
            text:
              'ワークスペースIDが存在しません。再ログインして再度お試しください。\nこのエラーが繰り返し表示される場合、COMSQUAREまでお問い合わせください。',
          });
        } else if (result === 'failure' && query === 'user_id_not_found') {
          setMessage({
            color: 'error',
            text:
              'ユーザーIDが特定できません。ログインし直してから再度お試しください。\nこのエラーが繰り返し表示される場合、COMSQUAREまでお問い合わせください。',
          });
        } else if (result === 'failure' && query === 'none') {
          setMessage({
            color: 'error',
            text: 'Googleカレンダーとの連携はキャンセルされました。',
          });
        } else {
          setMessage({
            color: 'error',
            text:
              'Googleカレンダーとの連携に失敗しました。\nこのエラーが繰り返し表示される場合、COMSQUAREまでお問い合わせください。',
          });
        }
        next({ name: 'Home' });
      });
    } catch (error: any) {
      Log.error(error);
      setMessage({ color: 'error', text: error.message });
    }
  },
  methods: {
    // ...UIGoogleCalendarMapper.mapActions(['setToastCheck']),
  },
});
